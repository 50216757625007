import React, { useCallback, useState } from 'react';
import {
  Container, Row, Col, Button
} from 'reactstrap';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { Layout, Carousel } from '../Components';
import defaultImage from '../assets/images/default-image.png';

const Home = ({
  data: {
    allWordpressPost: { edges },
  },
}) => {
  const [page, setPage] = useState(0);
  const paginate = useCallback((articles = [], size = 0, number = 0) => {
    if (number === 0 && size === 0) {
      return articles;
    }
    return articles.slice(0, (number + 1) * size);
  });
  const maxPage = Math.ceil(edges.length / 6) - 1;
  const posts = paginate(edges, 6, page);
  return (
    <Layout>
      <Container className="my-5">
        <Row>
          <Col md="12">
            <Carousel />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {posts.map(({ node }) => (
            <Col lg="6" key={node.slug}>
              <article className="my-5">
                <div className="mx-auto d-block">
                  <Link to={`/article/${node.slug}`}>
                    <span className="Home_PostDate">{node.date}</span>
                    {node.featured_media ? (
                      <Img
                        fixed={node.featured_media.localFile.childImageSharp.fixed}
                        className="img-fluid mx-auto d-block"
                      />
                    ) : (
                      <img src={defaultImage} alt={node.title} className="mx-auto d-block h-auto w-sm-100" />
                    )}
                  </Link>
                </div>
                <Link to={`/article/${node.slug}`}>
                  <h4 className="my-2" dangerouslySetInnerHTML={{ __html: node.title }} />
                </Link>
              </article>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {!(maxPage <= page) && (
              <Button
                type="primary"
                size="lg"
                className="d-block mx-auto text-center"
                disabled={maxPage <= page}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Voir plus d&apos;actualités
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default Home;
export const query = graphql`
  {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          content
          title
          slug
          date(formatString: "D/MM/YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 480, height: 270, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
